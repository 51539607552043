<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <CarrotTitle title="현장경영 스케줄">
            <div class="btn-ibbox title-btnbox ml-150 mb-10">
                <router-link :to="{ name:'customerManagement-scheduleVisitStatus' }"><button class="btn-default type2">현황보기</button></router-link>
            </div>
            - <span class="txt-s1">검정색글씨</span> : 외근 예정자 / <span class="txt-s2">하늘색글씨</span> : 고객사일지 작성자 / <span class="txt-s3">주황색글씨</span> : 고객사일지 미작성자
        </CarrotTitle>

        <div class="board">
            <div class="mt-40">
                <div v-if="bbs.isLoaded==true">

                    <div class="day-select mt-40">
                        <button @click="bbs.doPrev" class="btn btn-prev">prev</button>
                        <button @click="bbs.showCalendar" class="day btn-cal">
                            {{ bbs.sdate }} ~ {{ bbs.edate }}
                            <v-datepicker v-model="bbs.tdate" :attributes="bbs.attrs" @dayclick="bbs.doSelect" class="datepicker-group">
                                <template #default="{ inputValue, togglePopover }">
                                    <div>
                                        <input type="text" :value="inputValue" style="display:none;" />
                                        <button class="btn-weeks" @click="togglePopover()"></button>
                                    </div>
                                </template>
                            </v-datepicker>
                        </button>
                        <button @click="bbs.doNext" class="btn btn-next">next</button>
                    </div>
                    <div class="mt-40 mb-20">
                        <select v-model="bbs.state" class="w-200px mr-5" @change="bbs.doSelect">
                            <option value="">스케줄 전체</option>
                            <option value="MY">나의 스케줄</option>
                        </select>
                        <CarrotDept v-if="bbs.isMounted" @change="bbs.doSelect" v-model="bbs.office" class="w-200px"></CarrotDept>
                        <CarrotTeam v-if="bbs.isMounted" @change="bbs.doSelect" :idx_office="bbs.office" v-model="bbs.team" class="w-200px ml-5"></CarrotTeam>

                        <div class="clear"></div>
                    </div>
                  <div class="dp-table w-100per mt-40 mb-20">
                    <button @click="bbs.showWeeks" class="btn-default float-left w-70px mr-10">주별</button>
                    <button @click="bbs.showMonths" class="btn-default float-left w-70px">월별</button>
                    <button @click="bbs.addSchedule" class="btn-default float-right">스케줄 등록</button>
                  </div>

                    <table class="table-col table-cal">
                        <colgroup>
                            <col span="7">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>SUN</th>
                                <th>MON</th>
                                <th>TUE</th>
                                <th>WED</th>
                                <th>THU</th>
                                <th>FRI</th>
                                <th>SAT</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th v-for="(irow, i) in bbs.list" :key="i" :class="{ 'today':irow.vdate==bbs.today }">{{ irow.vdate }}</th>
                            </tr>
                            <tr>
                                <td v-for="(irow, i) in bbs.list" :key="i" :class="{ 'today':irow.vdate==bbs.today }">
                                    <template v-for="(jrow, j) in irow.times" :key="j">
                                        <div :class="{ 'txt-s1':jrow.state=='READY', 'txt-s2':jrow.state=='DONE', 'txt-s3':jrow.state=='VISIT' }" @mouseover="bbs.isShow=jrow.idx" @mouseleave="bbs.isShow=0" class="cal-item txt-left">
                                            <div @click="bbs.showSchedule(jrow.idx)" style ="
                                      white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
width:140px;">

                                              {{ jrow.hq_ename }} : {{ jrow.company_kname }}
                                            </div>
                                            <div v-if="bbs.isShow==jrow.idx" class="calDetail-layer">
                                                <ul class="calDetail-info">
                                                    <li>매니저 : {{ jrow.hq_ename }}({{ jrow.hq_kname }})</li>
                                                    <li>방문일시 : {{ jrow.vdate }} {{ jrow.vtime }}</li>
                                                    <li>고객사 : {{ jrow.company_kname }}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </template>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from '@vue/runtime-core';
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import { DatePicker } from 'v-calendar'
import CarrotDept from '@/components/common/CarrotDept.vue'
import CarrotTeam from '@/components/common/CarrotTeam.vue'


export default {
    layout:"customerManagement",
    components: {
        'v-datepicker' : DatePicker,
        CarrotDept,
        CarrotTeam
    },
    setup() {
        const router = new useRouter();
        const toast = useToast()

        const bbs = reactive({
            isMounted : false,
            isLoaded   : false,

            page : 1,
            rows : 50,

            today   : "",
            tdate   : "",
            attrs   : [
                { key:'selected', highlight:true, dates:[ new Date() ] },
            ],

            weeks   : [],

            sdate   : "",             // 
            edate   : "",             // 
            state   : "",             // schedule type
            office  : "0",            // hq office
            team    : "0",            // hq team

            list : [], total : 0,

            dateFormat : (d) => {
                return [d.getFullYear(), d.getMonth()>8?d.getMonth()+1:'0'+(d.getMonth()+1), d.getDate()>9?d.getDate():'0'+d.getDate()].join('-');
            },
            showMonths : () => {
              sessionStorage.setItem('scheduleVisitWeekly', JSON.stringify({
                sdate  : bbs.sdate,
                edate  : bbs.edate,
                state  : bbs.state,
                office : bbs.office,
                team   : bbs.team
              }));
              router.push({
                name : 'customerManagement-scheduleVisitMonthly'
              });
            },
            showWeeks : () => {
              sessionStorage.setItem('scheduleVisitWeekly', JSON.stringify({
                sdate  : bbs.sdate,
                edate  : bbs.edate,
                state  : bbs.state,
                office : bbs.office,
                team   : bbs.team
              }));
              router.push({
                name : 'customerManagement-scheduleVisitWeekly'
              });
            },
            addSchedule : () => {
                sessionStorage.setItem('scheduleVisitWeekly', JSON.stringify({
                    sdate  : bbs.sdate,
                    edate  : bbs.edate,
                    state  : bbs.state,
                    office : bbs.office,
                    team   : bbs.team
                }));

                router.push({ 
                    name:'customerManagement-scheduleVisitAdd' 
                });
            },

            showSchedule : (idx) => {
                sessionStorage.setItem('scheduleVisitWeekly', JSON.stringify({
                    sdate  : bbs.sdate,
                    edate  : bbs.edate,
                    state  : bbs.state,
                    office : bbs.office,
                    team   : bbs.team
                }));

                router.push({ 
                    name:'customerManagement-scheduleVisitView-idx', 
                    params:{ 
                        idx:idx 
                    } 
                });
            },

            doSelect : () => {
                let nd = new Date(bbs.tdate);
                let td = nd.getDate() - nd.getDay();
                let fd = new Date(nd.setDate(td));
                let ld = new Date(fd.getFullYear(), fd.getMonth(), fd.getDate()+6);
                bbs.sdate = [fd.getFullYear(), fd.getMonth()>8?fd.getMonth()+1:'0'+(fd.getMonth()+1), fd.getDate()>9?fd.getDate():'0'+fd.getDate()].join('-');
                bbs.edate = [ld.getFullYear(), ld.getMonth()>8?ld.getMonth()+1:'0'+(ld.getMonth()+1), ld.getDate()>9?ld.getDate():'0'+ld.getDate()].join('-');

                for(let i=0; i<7; i++){
                    bbs.attrs[0].dates[i] = new Date(fd.getFullYear(), fd.getMonth(), fd.getDate()+i);
                    bbs.weeks[i] = bbs.dateFormat(bbs.attrs[0].dates[i]);
                }
                bbs.doSearch();
            },

            doPrev : () => {
                let nd = new Date(bbs.sdate);
                bbs.tdate = new Date(nd.getFullYear(), nd.getMonth(), nd.getDate()-7);
                bbs.doSelect();
            },

            doNext : () => {
                let nd = new Date(bbs.sdate);
                bbs.tdate = new Date(nd.getFullYear(), nd.getMonth(), nd.getDate()+7);
                bbs.doSelect();
            },

            doSearch : () => {
                if( bbs.isMounted == false ) return;
                let params = {
                    sdate  : bbs.sdate,
                    edate  : bbs.edate,
                    state  : bbs.state,
                    office : bbs.office,
                    team   : bbs.team
                };

                axios.get("/rest/customermgr/getScheduleDates", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.list  = res.data.list;
                        bbs.total = res.data.total;

                        bbs.isLoaded = true;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }

                    sessionStorage.removeItem('scheduleVisitWeekly');
                });
            }
        });


        onMounted(() => {
            // Mounted
            let nd = new Date();
            bbs.today = bbs.dateFormat(nd);
            bbs.tdate = bbs.dateFormat(nd);
            bbs.doSelect();

            let ss = sessionStorage.getItem("scheduleVisitWeekly");
            if( ss ){
                ss = JSON.parse(ss);
                bbs.page  = ss.page?ss.page:1;
                if( ss.sdate ) bbs.sdate = ss.sdate;
                if( ss.edate ) bbs.edate = ss.edate;
                bbs.state  = ss.state?ss.state:"";
                bbs.office = ss.office>0?ss.office:0;
                bbs.team   = ss.team>0?ss.team:0;
            }

            bbs.isMounted = true;
            bbs.doSearch();

        });

        return {bbs};
    }
}
</script>

<style lang="scss" scoped>
.btn-weeks {
    position: absolute; top: 0; left: 0; width: 100%; height: 100%; border: none; opacity: 0.001; z-index: 1;
}
.cal-item {
    position:relative; cursor:pointer;
}
.calDetail-layer {
    position:absolute; top:20px; left:20px; width:220px; display:block; z-index:2; background-color:#f9f9f9; box-shadow: inherit;
}
.sub-wrap .con-wrap .table-col.table-cal tbody tr td {
    height:100px;
}
.table-cal {
    .today {
        background-color:rgba(255,250,231,1);
    }
}
</style>